import { Controller } from '@hotwired/stimulus'
import Timer from 'tiny-timer'
import Moment from 'moment'

export default class extends Controller {
  static targets = ['countdown', 'resendButton', 'form']

  connect() {
    if (this.hasCountdownTarget) {
      this._startTimer()
    }
  }

  clickResend(e) {
    if (this.resendButtonTarget.classList.contains('disabled')) {
      e.preventDefault()
    }
  }

  _enableResend() {
    this.resendButtonTarget.classList.remove('disabled')
    this.resendButtonTarget.innerHTML = I18n.t('js.views.insights.onboarding.phone_verify.form.resend_code')
  }

  _startTimer() {
    const timer = new Timer()
    const lockoutFinishedAt = Moment(this.data.get('lockout-finished-at'))
    const timeDiff = lockoutFinishedAt.diff(Moment())

    timer.on('tick', (ms) => { this.countdownTarget.innerHTML = Math.floor(ms / 1000) })
    timer.on('done', () => this._enableResend())

    timer.start(timeDiff, 1000)
  }
}
