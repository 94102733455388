import utils from './utils';

/* -------------------------------------------------------------------------- */
/*                               Navbar Vertical                              */
/* -------------------------------------------------------------------------- */

const handleNavbarVerticalCollapsed = () => {
  const toggleSidebarLocalState = () => {
    // Set collapse state on localStorage
    const isNavbarVerticalCollapsed = utils.getItemFromStore(
      'isNavbarVerticalCollapsed'
    );
    utils.setItemToStore(
      'isNavbarVerticalCollapsed',
      !isNavbarVerticalCollapsed
    );
  }
  
  const Selector = {
    HTML: 'html',
    NAVBAR_VERTICAL_TOGGLE: '.navbar-vertical-toggle',
    NAVBAR_VERTICAL_COLLAPSE: '.navbar-vertical .navbar-collapse',
    ECHART_RESPONSIVE: '[data-echart-responsive]',
    NAVBAR_VERTICAL_TOGGLE_MOBILE: '.navbar-vertical-toggle-mobile',
    OVERLAY_SIDEBAR: '.overlay-sidebar',
  };

  const Events = {
    CLICK: 'click',
    MOUSE_OVER: 'mouseover',
    MOUSE_LEAVE: 'mouseleave',
    NAVBAR_VERTICAL_TOGGLE: 'navbar.vertical.toggle',
  };
  const ClassNames = {
    NAVBAR_VERTICAL_COLLAPSED: 'navbar-vertical-collapsed',
    NAVBAR_VERTICAL_INITIALIZED: 'navbar-vertical-initialized',
    NAVBAR_VERTICAL_COLLAPSED_HOVER: 'navbar-vertical-collapsed-hover',
  };
  const navbarVerticalToggle = document.querySelector(
    Selector.NAVBAR_VERTICAL_TOGGLE
  );
  const navbarVerticalToggleMobile = document.querySelector(
    Selector.NAVBAR_VERTICAL_TOGGLE_MOBILE
  );
  
  const html = document.querySelector(Selector.HTML);
  const navbarVerticalCollapse = document.querySelector(
    Selector.NAVBAR_VERTICAL_COLLAPSE
  );
  const overlaySidebar = document.querySelector(
    Selector.OVERLAY_SIDEBAR
  );

  if (navbarVerticalToggle) {
    navbarVerticalToggle.addEventListener(Events.CLICK, (e) => {
      navbarVerticalToggle.blur();
      html.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED);


      toggleSidebarLocalState();

      const event = new CustomEvent(Events.NAVBAR_VERTICAL_TOGGLE);
      e.currentTarget.dispatchEvent(event);
    });
  }

  // Controls mobile overlay
  if(overlaySidebar){
    overlaySidebar.addEventListener(Events.CLICK, (e) => {
      navbarVerticalToggle.blur();
      html.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED);
      html.classList.remove(ClassNames.NAVBAR_VERTICAL_INITIALIZED);

      toggleSidebarLocalState();

      const event = new CustomEvent(Events.NAVBAR_VERTICAL_TOGGLE);
      e.currentTarget.dispatchEvent(event);
    });
  }

  if (navbarVerticalToggleMobile) {
    navbarVerticalToggleMobile.addEventListener(Events.CLICK, (e) => {
      navbarVerticalToggle.blur();

      if(Array.from(html.classList).includes(ClassNames.NAVBAR_VERTICAL_INITIALIZED)){
        html.classList.remove(ClassNames.NAVBAR_VERTICAL_INITIALIZED);
      }else{
        html.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED);
      }

      toggleSidebarLocalState();

      const event = new CustomEvent(Events.NAVBAR_VERTICAL_TOGGLE);
      e.currentTarget.dispatchEvent(event);
    });
  }

  if (navbarVerticalCollapse) {
    navbarVerticalCollapse.addEventListener(Events.MOUSE_OVER, () => {
      if (utils.hasClass(html, ClassNames.NAVBAR_VERTICAL_COLLAPSED)) {
        html.classList.add(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
      }
    });
    navbarVerticalCollapse.addEventListener(Events.MOUSE_LEAVE, () => {
      if (utils.hasClass(html, ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER)) {
        html.classList.remove(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
      }
    });
  }
};

export default handleNavbarVerticalCollapsed;
