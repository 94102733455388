import { fetch } from 'whatwg-fetch';

window.$ = jQuery;
window.jQuery = jQuery;
window.fetch = fetch;

// This package is from 2012. Sucks can't use ES6.
require('daterangepicker')

import Rails from '@rails/ujs';
Rails.start();
window.Rails = Rails;

// Importing image context for static webpack server
require.context('images', true, /\.(png|jpe?g|svg|ico)$/);

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import is from 'is_js'
window.is = is

import * as echarts from 'echarts'
window.echarts = echarts

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap

import * as OverlayScrollbars from 'overlayscrollbars'
window.OverlayScrollbars = OverlayScrollbars

import 'list.js'

import '@fortawesome/fontawesome-free/js/all'

import * as AnchorJS from 'anchor-js'
window.AnchorJS = AnchorJS

import * as flatpickr from 'flatpickr'
window.flatpickr = flatpickr

import * as Draggable from '@shopify/draggable'
window.Draggable = Draggable

import * as dayjs from 'dayjs'
window.dayjs = dayjs

import 'fullcalendar/main.min' // Doesn't work, has no export function

import 'lodash'
import '../falcon/theme';
import './ahoy_tracking';

import { I18n } from 'i18n-js'; 
import translations from "../locales/translations.json";

const userLocale = document.documentElement.lang;

export const i18n = new I18n();
i18n.store(translations);
i18n.defaultLocale = "en";
i18n.enableFallback = true;
i18n.locale = userLocale;
window.I18n = i18n;

import '../falcon/config';
