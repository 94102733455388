import { Controller } from '@hotwired/stimulus'

import 'intl-tel-input/build/js/utils'
import IntlTelInput from 'intl-tel-input/build/js/intlTelInput'

export default class extends Controller {
  static targets = ['phoneInput', 'validMsg', 'errorMsg', 'phoneForm']

  static errorMap = [
    'The phone number is invalid, please re-enter',
    'Invalid country code',
    'The phone number is too short, please re-enter',
    'The phone number is too long, please re-enter',
    'The phone number is invalid number, please re-enter'
  ]

  connect() {
    // We wait for EVERYTHING to load first
    // https://github.com/jackocnr/intl-tel-input/issues/965
    ['load', 'turbo:load'].forEach( evt => 
      window.addEventListener(evt, () => this._loadPhoneInput())
    )

    this._phoneFormValidation()
    this._loadPhoneInput()
  }

  _loadPhoneInput() {
    if (this.iti) return;

    this.iti = new IntlTelInput(this.phoneInputTarget, {
      formatOnInit: true,
      separateDialCode: true,
      utilsScript: 'assets/libphonenumber/utils.js',
      preferredCountries: [this.data.get('countryCode2')],
      hiddenInput: 'account[phone_number]',
      formatOnDisplay: true,
    })

    this.phoneInputTarget.addEventListener('blur', () => this._validateInput())
    this.phoneInputTarget.addEventListener('change', () => this._resetValidationsreset)
    this.phoneInputTarget.addEventListener('keyup', () => this._resetValidationsreset)
  }

  _validateInput() {
    this._resetValidations()

    if (!this.phoneInputTarget.value.trim()) { return false }

    if (this.iti.isValidNumber()) {
      this.validMsgTarget.classList.remove('hide')
      return true

    } else {
      const errorCode = this.iti.getValidationError()

      this.phoneInputTarget.classList.add('field_with_errors')
      this.errorMsgTarget.innerHTML = this.constructor.errorMap[errorCode] || this.constructor.errorMap[0]
      this.errorMsgTarget.classList.remove('hide')
      return false
    }
  }

  _resetValidations() {
    this.phoneInputTarget.classList.remove('field_with_errors')
    this.errorMsgTarget.innerHTML = ''
    this.errorMsgTarget.classList.add('hide')
    this.validMsgTarget.classList.add('hide')
  }

  _phoneFormValidation(e) {
    if (!this.phoneFormTarget) return
    var self = this;

    this.phoneFormTarget.addEventListener('submit', function (e) {
      var isValid = self._validateInput()

      if (isValid) {
        document.querySelector('input[type=submit]').setAttribute('disabled', true)
        return
      }

      e.preventDefault()
    })
  }
}
